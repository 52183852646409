import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import shortid from "shortid"

import { LocaleContext } from "../contexts/LocaleContext"
import SEO from "../components/SEO.js"

const NewsPage = ({
  data: { newsPage, newsCategories },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const meta = {
    title: newsPage.data.meta_title,
    description: newsPage.data.meta_description,
    keywords: newsPage.data.meta_keywords,
    author: "Emil Mureșan",
  }

  const schema = {
    "@graph": [],
  }
  const ogp = []

  ogp.push({
    property: "og:image",
    content: newsPage.data.cover.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: newsPage.data.cover.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: newsPage.data.cover.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: newsPage.data.cover.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const categories = newsCategories.edges.map(r => r.node)

  const getLink = item =>
    `${i18n.path}/${item.data.parent_page.document.uid}/${item.uid}/`

  return (
    <>
      <SEO
        schema={JSON.stringify(schema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
      />
      <div id="news">
        <Img
          className="no-border full-image"
          alt={newsPage.data.cover.alt}
          fluid={newsPage.data.cover.fluid}
        />

        <div className="vcard">
          <h1>{newsPage.data.title.text.toUpperCase()}</h1>

          <ul className="menu">
            {categories.map(category => (
              <li key={shortid.generate()}>
                <Link to={getLink(category)}>
                  {category.data.title.text.toUpperCase()}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default NewsPage

export const query = graphql`
  query NewsPageQuery($slug: String!, $locale: String!) {
    newsPage: prismicNewsPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      data {
        title {
          text
        }
        description {
          text
        }
        cover {
          alt
          dimensions {
            height
            width
          }
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    newsCategories: allPrismicNewsCategory(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            title {
              text
            }
            parent_page {
              document {
                ... on PrismicNewsPage {
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
          lang
        }
      }
    }
  }
`

NewsPage.propTypes = {
  data: PropTypes.shape({
    newsPage: PropTypes.object.isRequired,
    newsCategories: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
